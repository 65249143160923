// This is a client-specific theme file

// Common

body {
  font-family: 'BMW Type Web Light All', sans-serif;
}

body.is-locked:after {
  content: '';
  display: block;
  position: fixed; // could also be absolute
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1050;
  background-color: rgba(0, 0, 0, 0.2);
}

.tooltip {
  z-index: 1051;
  filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.8));
}

.tooltip-inner {
  width: 300px;
  max-width: 300px;
}

//

.text-small {
  font-size: 0.8rem;
}

.text-smaller {
  font-size: 0.6rem;
}

.sidebar {
  font-family: 'BMW Type Web Bold All', sans-serif;
  font-weight: bold;
}

.sidebar .nav-icon {
  font-weight: bold;
  font-size: 125%;
}

.main .card {
  -webkit-box-shadow: 5px 10px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 10px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 10px 15px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #f0f0f0;
}

.btn {
  font-family: 'BMW Type Web Bold All', sans-serif;
  font-weight: bold;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

.table-striped tbody tr:nth-of-type(odd):hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.modal-header {
  background-color: #f7f9f9;
}

.card-header {
  background: white;
  border: none;
}

.card-header.secondary {
  background-color: #f7f9f9;
  border: none;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px #f0f0f0 solid;
}

.coins-widget .card-body {
  padding-top: 0px;
}

#otpCode {
  font-size: 22px;
  text-align: center;
  letter-spacing: 10px;
}

.sidebar .nav-item {
  display: none;
}

.sidebar .nav-item.role-all,
.sidebar .nav-item.nav-dropdown {
  display: list-item;
}

.role-global .sidebar .nav-item.role-global {
  display: list-item;
}

.role-kr .sidebar .nav-item.role-kr {
  display: list-item;
}
.role-department .sidebar .nav-item.role-department {
  display: list-item;
}

.role-partner .sidebar .nav-item.role-partner {
  display: list-item;
}

.button-center {
  margin: 0px auto !important;
}

.confirm-otp-check {
  font-size: 60px;
  font-weight: bold !important;
}

.min-height-290 {
  min-height: 290px;
}

/* Hide HTML5 Up and Down arrows. */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.field-icon,
.toggle-password,
.toggle-password-alt {
  float: right;
  margin-left: -25px;
  margin-top: 10px;
  position: relative;
  z-index: 99;
  color: #a0a0a0;
}

.toggle-password-alt {
  margin-top: 7px;
}

.sidebar {
  font-size: 12px;
}

.sidebar .nav-link {
  .nav-icon {
    color: var(--primary);
  }
}

.sidebar .sidebar-header {
  background-color: black;
  color: #fff;
  font-family: 'BMW Type Web Light All', sans-serif;
  padding: 1.5rem 1rem;
}

// Data Tables

.react-bs-table {
  font-size: 11px;
}

.react-bs-table-tool-bar .col-xs-6.col-sm-6.col-md-6.col-lg-8 {
  display: none;
}

.react-bs-table-tool-bar {
  margin-bottom: 10px !important;
}

//.react-bs-table-search-form:before {
//  content: "\f002";
//  font-family: "Font Awesome 5 Free";
//  font-style: normal;
//  font-weight: 900;
//  text-decoration: inherit;
//  /*--adjust as necessary--*/
//  color: #5c6873;
//  font-size: 18px;
//  padding-right: 0.5em;
//  position: absolute;
//  top: 2px;
//  left: 0;
//  z-index: 99;
//}

.react-bs-table-search-form input {
  padding-left: 20px;
}

.react-bs-table-search-clear-btn {
  display: none;
}

.react-bs-container-body table {
  border-bottom: 2px solid #c8ced3;
}

.table-column-hidden {
  border: none !important;
}

.table-column-group,
.table-column-group-light {
  text-align: center !important;
  background-color: #c8ced3;
  padding: 0.25rem !important;
}
.table-column-group-light {
  background-color: #f9f9f9;
}

// Login

.login-page {
  background: white url('assets/bmw/cover.png') fixed no-repeat;
  background-size: 90%;
  background-position: center;
}

.login-page .card-group {
  -webkit-box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.25);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.25);
}

.login-page h1 {
  text-transform: uppercase !important;
}

.login-page h3 {
  font-weight: bold;
}

.login-page .btn {
  font-weight: bold;
}

.login-page .forgot-password-link {
  font-weight: bold;
}

// Dashboard

.dashboard-welcome h2 {
  text-transform: uppercase !important;
}

.create-reward-button {
  float: right;
}

.react-bs-table-search-form > input.form-control {
  font-size: 14px !important;
  margin-right: 5px;
}

.reward_type_button_group {
  width: 100%;
  min-height: 120px;
  .reward_type_button {
    color: #23282c;
    text-align: left;
    .reward_type_tickbox {
      float: right;
      font-size: 24px;
      font-weight: bold;
    }
  }
}

.coin-modal input[type='text'],
.coin-modal input[type='number'] {
  text-align: center;
  padding: 20px;
  font-size: 40px;
}

// Dots
.dot {
  display: inline-block;
  border-radius: 50%;
  background-color: #eee;
  height: 8px;
  width: 8px;
  margin: 0 2px;
}
.dot.dot-success,
.dot.dot-complete,
.dot.dot-completed,
.dot.dot-active,
.dot.dot-successful,
.dot.dot-approved,
.dot.dot-accepted {
  background-color: #449d44;
}
.dot.dot-warning,
.dot.dot-pending,
.dot.dot-incomplete {
  background-color: #f0ad4e;
}
.dot.dot-danger,
.dot.dot-error,
.dot.dot-failed,
.dot.dot-voided {
  background-color: #d9534f;
}
.dot.dot-secondary,
.dot.dot-inactive,
.dot.dot-disabled {
  background-color: #666666;
}
.dot.dot-sm {
  height: 10px;
  width: 10px;
}
.dot.dot-lg {
  margin: 0 4px;
  height: 20px;
  width: 20px;
}

.analytics-widget .table th {
  border-top: none !important;
  font-size: 10px;
  font-weight: normal;
}

.analytics-widget .table-progress {
  border: none !important;
  padding-top: 0px;
  padding-bottom: 0.5rem !important;
}

.analytics-widget .table-progress .progress {
  height: 0.25rem;
}

.analytics-top-events-widget .table td {
  padding-top: 0.4rem;
  padding-bottom: 0rem;
}

//
//
.fileContainer {
  border-width: 2px !important;
  border-radius: 2px !important;
  border-color: rgb(238, 238, 238) !important;
  border-style: dashed !important;
  background-color: rgb(250, 250, 250) !important;
  color: rgb(189, 189, 189) !important;
  box-shadow: none !important;
}

.fileContainer p {
  text-align: center;
}

//
//
.thumbnail-preview {
  display: flex;
  flex-flow: row wrap;
}

.thumbnail-cover-container {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid rgb(234, 234, 234);
  margin-bottom: 8px;
  margin-right: 8px;
  width: 300px;
  height: 480px;
  padding: 4px;
  box-sizing: border-box;
}

.thumbnail-container {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid rgb(234, 234, 234);
  margin-bottom: 8px;
  margin-right: 8px;
  width: 300px;
  height: 105px;
  padding: 4px;
  box-sizing: border-box;
}

div.thumbnail {
  display: flex;
  min-width: 0px;
  overflow: hidden;
}

img.thumbnail {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.logo {
  display: flex;
  flex-flow: row wrap;
}

.logo-container {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid rgb(234, 234, 234);
  margin-bottom: 8px;
  margin-right: 8px;
  width: 180px;
  height: 180px;
  padding: 4px;
  box-sizing: border-box;
}

div.logo {
  display: flex;
  min-width: 0px;
  overflow: hidden;
}

img.logo {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.popover.datepicker {
  max-width: none;
}

.datepicker-button:hover {
  text-decoration: none !important;
}

//.popover.datepicker .rdrInputRanges {
//  display: none;
//}

.react-bs-table th[rowspan='2'] {
  border-top: none;
  vertical-align: bottom;
}

.customer-group-select {
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
}

.ql-editor {
  min-height: 200px;
}

.text-underline {
  text-decoration: underline;
}

.rounded-circle.text-white.badge.badge-warning {
  background-color: #fee083;
  width: 55px;
  font-weight: 900;
  font-size: 60%;
}

.MuiButton-root.text-underline {
  text-decoration: underline;
}

.input-group.is-invalid {
  border-color: #f86c6b;
  padding-right: calc(1.5em + 0.75rem);
  background-image: none;
}

.form-control.is-invalid {
  border-color: #f86c6b;
  padding-right: calc(1.5em + 0.75rem);
  background-image: none;
}

.quill.is-invalid > .ql-toolbar.ql-snow,
.quill.is-invalid > .ql-container.ql-snow {
  border-color: #f86c6b;
}

.form-control.is-password-invalid {
  border-color: #f86c6b;
}

.PasswordInput.is-invalid .input-group-text {
  border-color: #f86c6b;
}

.PasswordInput.is-invalid ~ .invalid-feedback {
  display: block;
}

.customer-group-select ~ .invalid-feedback {
  display: block;
}

.react-phone-number-input.form-control {
  padding-top: 0px;
}

.react-phone-number-input__input {
  border: none;
  border-bottom: none !important;
  -webkit-transition: border 0ms;
  -moz-transition: border 0ms;
  -ms-transition: border 0ms;
  -o-transition: border 0ms;
  transition: border 0ms;
}

.react-phone-number-input__icon {
  height: auto !important;
  border: none !important;
}

.react-phone-number-input.is-disabled,
.react-phone-number-input__input:disabled {
  background-color: #e4e7ea;
  opacity: 1;
}

//
//
//

.MuiInputBase-root {
  font-family: 'BMW Type Web Light All', sans-serif !important;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873 !important;
  // border: 1px solid #e4e7ea;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.MuiSelect-select:focus {
  border-radius: inherit !important;
  background-color: inherit !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--primary);
}

.MuiIconButton-colorSecondary {
  color: var(--primary);
}

.MuiInputBase-root.Mui-disabled,
.ql-disabled {
  background-color: #e4e7ea;
}

//
//
//

.analytics-doughnut {
  max-height: 350px;
}

//
// Buttons
//

.btn-group.btn-group-block {
  display: flex;
}

.btn-group-block .btn {
  flex: 1;
}

.btn-xs,
.btn-group-xs > .btn {
  padding: 0.1rem 0.3rem;
  font-size: 0.66rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

//
//
//

.form-control.editor.edit-select {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

//
//
//
.analytics-top-rewards td {
  font-size: 12px;
}

.analytics-top-rewards-row {
  font-size: 12px;
  border-top: 1px solid #c8ced3;
  padding: 0.75rem 0;
  margin: 0 0.15rem;
}

.col-clickable:hover {
  background-color: #f0f0f0;
}

//
// Custom card title dropdowns
//
.card-title .btn,
.card-title .btn-secondary {
  padding: 0;
  font-family: 'BMW Type Web Light All', sans-serif !important;
  background-color: inherit !important;
  border: none !important;
  font-weight: normal;
}

//
.background-black {
  background: black !important;
}

.flat-card .card {
  border: none;
  margin-bottom: 0;
}
