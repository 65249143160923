.mobilepreview_title {
  color: #ffffff;
  font-family: 'BMW Type Web Light All';
  font-size: 32px;
  line-height: 34px;
  position: absolute;
  top: 93px;
  left: 45px;
  text-align: left;
  height: 80%;
  overflow-y: auto;
  width: 84%;

  .mobilepreview_arrow {
    height: 16px;
    width: 16px;
    font-size: 20px;
  }
  .mobilepreview_subtitle {
    font-size: 16px;
    line-height: 18px;
  }
}

.mobilepreview_readmore {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  height: 30px;
  color: black;
  position: absolute;
  top: 809px;
  left: 170px;
  background-color: white;
  padding: 4px 15px 4px 15px;
  font-family: 'BMW Type Web Bold All';
}

.thumbnailImage {
  position: absolute;
  width: 161px;
  height: 130px;
  top: 283px;
  left: 24px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  border-radius: 3px;
}

.thumbnailstarbox {
  position: absolute;
  width: 80px;
  height: 121px;
  top: 289px;
  left: 299px;
  color: red;
  font-size: 24px;
}

.thumbnailtext_viewmore {
  position: absolute;
  height: 30px;
  text-align: center;
  padding: 1px;
  top: 284px;
  left: 200px;
  width: 75px;
  top: 384px;
  border: 3px solid red;
  border-radius: 5px;
  font-weight: 600;
}

.thumbnailtext_bmw {
  top: 274px;
  left: 51px;
  width: 48px;
  height: 22px;
  font-size: 10px;
  text-align: center;
  padding: 2px 0px 2px 0px;
  background-color: grey;
}

.CoverImagehalf {
  position: absolute;
  width: 327px;
  height: 200px;
  top: 168px;
  left: 25px;
  border-radius: 5px;
}

.CoverImagecontent {
  color: #ffffff;
  font-family: 'BMW Type Web Light All';
  font-size: 32px;
  line-height: 34px;
  position: absolute;
  top: 40px;
  left: 15px;
  text-align: left;
  height: 32%;
  width: 94%;
  overflow: hidden;
}

.CoverImage_arrow {
  height: 16px;
  width: 16px;
  font-size: 24px;
}

.CoverImage_title {
  margin-bottom: 9px;
  overflow-wrap: break-word;
}
.CoverImage_subtitle {
  font-size: 16px;
  line-height: 18px;
  display: flex;
}

.preview_event_info {
  height: 94%;
  overflow-y: scroll;
  width: 375px;
  border-radius: 40px;
  position: absolute;
}

.rewards_thumbnailimage {
  position: absolute;
  width: 160px;
  height: 131px;
  top: 338px;
  left: 23px;
}

.rewards_thumbnailpurchase {
  left: 299px;
  top: 420px;
  width: 80px;
}

.rewards_thumbnailstarbox {
  top: 323px;
  left: 299px;
  color: white;
  font-size: 24px;
}

.rewards_thumbnailtext_bmw {
  top: 339px;
  padding: 4px 0px 4px 0px;
}

.rewards_thumbnail_title {
  top: 412px;
  height: 40px;
  width: 210.4px;
  color: #ffffff;
  font-family: 'BMW Type Web Light All';
  font-size: 14px;
  line-height: 22px;
  position: absolute;
  text-align: left;
  left: 61px;
  overflow-wrap: break-word;
  overflow: hidden;
}

.rewards_coverImagehalf {
  position: absolute;
  width: 325px;
  height: 200px;
  top: 207px;
  z-index: 99;
  border-radius: 5px;
  left: 25px;
}

.rewards_CoverImagecontent {
  height: 22%;
  width: 92%;
  top: 16px;
}

.broadcast_close {
  position: absolute;
  color: white;
  top: 83px;
  left: 375px;
}

.broadcast_button {
  position: absolute;
  color: white;
  top: 751px;
  color: #ffffff;
  font-family: 'BMW Type Web Bold All';
  font-size: 16px;
  left: 43px;
}

.rewards_120 {
  font-size: 16px;
  height: 18px;
  position: absolute;
  width: 100%;
  text-align: right;
}

.reward_thumbnail_120 {
  margin-bottom: 4px;
  margin-top: 23px;
  height: 18px;
  width: 47.5px;
  color: #ffffff;
  font-family: 'BMW Type Web Bold All';
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-left: 16px;
}

.reward_thumbnail_bmwcoin {
  height: 14px;
  width: 64px;
  color: #ffffff;
  font-family: 'BMW Type Web Light All';
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  margin-bottom: 7px;
  margin-left: 10px;
}

.rewards_bmw {
  font-size: 10px;
  position: absolute;
  width: 100%;
  text-align: right;
}

.preview_reward_info {
  height: 93%;
  overflow-y: auto;
  width: 376px;
  border-radius: 40px;
  position: absolute;
}

.preview_reward_info::-webkit-scrollbar {
  display: none;
}

.preview_event_info::-webkit-scrollbar {
  display: none;
}

.newsevent_thumbnail_title {
  top: 285px;
  height: 78px;
  width: 120px;
  color: black;
  line-height: 15px;
  position: absolute;
  text-align: left;
  left: 200px;
  overflow-wrap: break-word;
  overflow: hidden;
  font-weight: 600;
}

.newsevent_thumbnail_title > p,
.rewards_thumbnail_title > p {
  position: absolute;
}

.f-10 {
  font-size: 10px;
}

.preview_reward_info > img {
  position: relative;
  width: 375px;
}

.image_gradient {
  height: 100%;
  width: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5802696078431373) 0%, rgba(255, 255, 255, 0) 100%);
  opacity: 0.6;
}
