@keyframes no-ie-container-move {
  from {
    top: -100px;
  }
  to {
    top: 0px;
  }
}

.no-ie-container {
  background-color: #000;
  height: 72px;
  width: 100%;
  text-align: center;
  position: absolute;
  display: table;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  left: 0;
  animation-name: no-ie-container-move;
  animation-duration: 2.5s;
}

.no-ie-text-button-container {
  display: table-cell;
  vertical-align: middle;
}

.no-ie-text {
  font-size: 1.1rem;
  color: #fff;
  margin-right: 24px;
  vertical-align: middle;
}
