// Styles

// Import Font Awesome Icons Set
// @import '~font-awesome/css/font-awesome.min.css';
// Import Font Awesome 5 Icons Set
@import '~@fortawesome/fontawesome-free/css/all.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';
