// Import styles with default layout.
// If you are going to use dark layout please comment next line
@import '~@coreui/coreui-pro/scss/coreui';

// Import styles with dark layout
// If you want to use dark layout uncomment next line
//@import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui-pro/scss/_dropdown-menu-right.scss';

@import '~react-date-range/dist/styles.css'; // main style file
@import '~react-date-range/dist/theme/default.css'; // theme css file

@import '~react-grid-layout/css/styles.css';
@import '~react-resizable/css/styles.css';

@import 'vendors/devices';

// If you want to add something do it here
@import '_colors';
@import 'themes/unify-custom';
@import '_preview';

// ie fixes
@import 'ie-fix';

// temp fixes
@import 'fixes';

// Spinkit
$spinkit-spinner-color: $body-color;
@import '~spinkit/spinkit.min.css';

// Toastr
@import 'vendors/toastr.min.scss';

.analytics-widget .card-title {
  font-size: 12px !important;
}

.form-check-label {
  font-weight: bold;
  font-size: large;
}

.form-select {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

select.form-select[size],
select.form-select[multiple] {
  height: auto;
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.app-body .main {
  padding-bottom: 15px;
}
